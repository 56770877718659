import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import moment from 'moment'
import "@/assets/scss/app.scss";

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

import { initFirebaseBackend } from './helpers/firebase/authUtils';

//import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  //configureFakeBackend();
}

Vue.config.productionTip = false
Vue.use(VueYoutube)

Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})

Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);

Vue.component('apexchart', VueApexCharts);

moment.locale('fr');

Vue.filter('defaultFormatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
  }
});

Vue.filter('messageListDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMM HH:mm')
  }
});


Vue.filter('fileDateFormat', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY')
  }
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
