import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';
import {cryptUtil} from "@/utils/crypt.util";

export const userService = {
    create,
    update,
    get,
    localUserInfo
};

async function create(userdata) {
    try {
        let ret = await axiosInstance.post(`/users`, userdata);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`/users`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function update() {
    try {
        let ret = await axiosInstance.put(`/users`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

function localUserInfo() {
    const sessionUser = cryptUtil.decrypt(sessionStorage.getItem('ECMRL'), 'shalomat-encrypt');
    return  sessionUser ? JSON.parse(sessionUser) : null;
}



