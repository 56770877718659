import axios from 'axios';
import errorHandler from '../handler/erroHandler';
import { authService } from '../auth/authentication.service';
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
})

axiosInstance.defaults.headers.common['Access-Control-Max-Age'] = "600";


axiosInstance.interceptors.request.use(function (request) {
    if (!request) {
        request = {};
    }
    if (!request.headers) {
        request.headers = {};
    }
    if (request.url != '/auth/login') {
        request.headers.Authorization = `Bearer ${authService.getToken()}`;
    }
    return request;

}, function (error) {
    errorHandler(error);
});

axiosInstance.interceptors.response.use(function (response) {
    
    return response;

}, function (error) {
    errorHandler(error);
});

export default axiosInstance;