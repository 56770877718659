import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import { cryptUtil } from "../utils/crypt.util";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  state: {
    userInfo: null,
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    setUserInfo(state, newUserInfo) {
      state.userInfo = newUserInfo;
    }
  },
  actions: {
    loadUserInfoState({ commit }) {
      const data = cryptUtil.decrypt(sessionStorage.getItem('ECMRL'), 'shalomat-encrypt')
      const userInfo = JSON.parse(data);
      if (userInfo) {
        commit('setUserInfo', userInfo)
      }
    }
  },
  plugins: [
    store => {
      store.subscribe((mutation, state) => {
        const encryptedRoles = cryptUtil.encrypt(JSON.stringify(state.userInfo), 'shalomat-encrypt');
        sessionStorage.setItem('ECMRL', encryptedRoles);
      })
    }
  ]
})

export default store

