import Cryptr from 'cryptr';
import erroHandler from '../../services/handler/erroHandler';
import axiosInstance from '../../services/manager/manager.service';
import {cryptUtil} from "@/utils/crypt.util";

export const authService = {
    login,
    saveToken,
    getToken,
    deleteToken,
    logOut,
    isLogged,
    setRemenberMe,
    getRememberME,
    deleteRememberMe,
    localUserInfo,
    changePassword
};
let crypt = new Cryptr(process.env.VUE_APP_REMEMBER_CRYPT);

async function login(login) {

    try {
        let rets = await axiosInstance.post(`/users/auth/signin`, login);
        if (rets) {
            saveToken(rets.data.token);
            return rets;
        }
        return 400;
    } catch (error) {
        return erroHandler(error);
    }
}

async function changePassword(userId, oldPassword, newPassword) {
    try {
        let rets = await axiosInstance.put(`/users/${userId}/password`, { 'oldpass': oldPassword, 'newpass': newPassword });
        if (rets) {
            saveToken(rets.data.token);
            return rets;
        }
        return 400;
    } catch (error) {
        return erroHandler(error);
    }
}

function saveToken(token) { 
    sessionStorage.setItem('accessToken', token);
}


function getToken() {
    return sessionStorage.getItem('accessToken');
}

function deleteToken() { 
    sessionStorage.removeItem('accessToken');
}

function logOut() {
    
}

function isLogged() {
    return !! getToken();
}

function setRemenberMe(username, password, remember) {
    const data = {
        'username': crypt.encrypt(username),
        'password': crypt.encrypt(password),
        'remember': remember
    }
   
    localStorage.setItem('remember', JSON.stringify(data))
}

function getRememberME() {
    let data = localStorage.getItem('remember');
    if (data != null) {
        data = JSON.parse(data);
        data.username = crypt.decrypt(data.username);
        data.password = crypt.decrypt(data.password);
        return data;
    }
    return null;
}

function deleteRememberMe() {
    localStorage.removeItem('remember');
}

function localUserInfo() {
    const sessionUser = cryptUtil.decrypt(sessionStorage.getItem('GZUI'), 'gozik-encrypt');
    return  JSON.parse(sessionUser);
}
